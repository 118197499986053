<template>
  <div
    class="app-club-name flex items-center"
    :class="{ 'cursor-pointer': clubId != null, 'flex-col': isFlexCol }"
    @click.stop="showClubProfile"
  >
    <div
      v-if="country"
      class="app-club-name-flag"
      :style="{
        backgroundImage: 'url(' + pathImages + 'flags/102x68/' + country + '.avif)',
      }"
    />
    <clubs-avatar
      v-if="clubLogoId || clubsBackgroundId"
      :logo-id="Number(clubLogoId)"
      :background-id="Number(clubsBackgroundId)"
      class="app-club-name-logo"
    />
    <section class="app-club-name-name-wrapper">
      <short-text
        class="app-club-name-name text-32"
        :class="{
          'font-bold': boldName,
          'text-texts-standard-dark':
            ($isWsm && (isTableMeRow || darkText)) || ($isSsm && darkText),
          'text-texts-standard-name':
            ($isWsm && !(isTableMeRow || darkText)) || ($isSsm && !darkText),
        }"
        :text="name ?? ''"
        :first-cut="20"
        :second-cut="30"
      />
    </section>
  </div>
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import ShortText from '@/components/ShortText.vue'
import { pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'AppClubName',
  components: {
    ClubsAvatar,
    ShortText,
  },
  props: {
    country: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    clubId: {
      type: String,
      default: '',
    },
    isTableMeRow: {
      type: Boolean,
      default: false,
    },
    darkText: {
      type: Boolean,
      default: false,
    },
    boldName: {
      type: Boolean,
      default: false,
    },
    clubLogoId: {
      type: [String, Number],
      default: 1,
    },
    clubsBackgroundId: {
      type: [String, Number],
      default: 1,
    },
    isFlexCol: {
      type: Boolean,
      default: false,
    },
    isHomepageScreen: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  methods: {
    showClubProfile(): void {
      if (this.clubId) {
        this.$router.push({
          name: this.$getWebView('ClubsProfile'),
          params: { clubProfileId: this.clubId },
          query: {
            homepage: this.isHomepageScreen.toString(),
            redirectFrom: this.$route.query.redirectFrom ?? undefined,
          },
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.app-club-name {
  &-country {
    width: 3.75rem;
    margin: 1.8rem 0 0.875rem 0;
  }

  &-flag {
    background-repeat: no-repeat;
    background-size: contain;
    width: 3.15rem;
    height: 2.2rem;
  }

  &-logo {
    width: 3rem;
    height: 3rem;
    margin: 1rem;
  }

  &-name {
    text-shadow: 0.643px 0.766px 0.93px rgba(0, 0, 0, 0.71);
    max-width: 20rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.text-texts-standard-dark {
      text-shadow: none;
    }
  }
}
</style>
